import {Box, Button, CircularProgress, Grid, IconButton, InputBase, Typography} from "@mui/material";
import {FilterList, Search} from '@mui/icons-material';
import {observer} from "mobx-react-lite";
import React, {useEffect, useMemo, useState} from "react";
import {useStore} from "../../app/stores/store";
import NodeCard from "./NodeCard";
import {NodeLabelIconData} from "./NodeCardLabelIcon";
import SortMenu from "../../app/common/SortMenu";
import {useDebounce} from "../../app/common/UtilHooks";

type SortType = "none" | "title";

const SortTypes: Array<{
  type: SortType,
  title: string,
}> = [
  {
    type: "none",
    title: "None",
  },
  {
    type: "title",
    title: "Titel",
  },
]

export default observer(function NodesScreen() {

  const {nodeStore, accountStore} = useStore()
  const {loadNodes, clearNodes, nodes, clearSelectedNode, loading} = nodeStore

  const [searchString, setSearchString] = useState('')
  const debouncedSearchString = useDebounce(searchString.trim(), 500)

  const [sortType, setSortType] = useState<SortType>("none");
  const [includeReadonly, setIncludeReadonly] = useState(true);

  useEffect(() => {
    clearNodes()
    clearSelectedNode()
    return () => {
      clearNodes()
      clearSelectedNode()
    }
  }, [clearNodes, clearSelectedNode])

  useEffect(() => {
    if (debouncedSearchString.length > 3) {
      loadNodes(debouncedSearchString)
    } else {
      clearNodes()
    }
  }, [debouncedSearchString, loadNodes])

  const allNodes = useMemo(
    () => {
      let result = nodes?.slice() ?? [];

      if (!includeReadonly) {
        result = result?.filter(value => accountStore.user?.organization.id === value?.organizationId)
      }

      switch (sortType) {
        case "none":
          break;
        case "title":
          result = result.sort((a, b) => a.title.localeCompare(b.title))
          break;
      }

      return result;
    },
    [nodes, sortType, includeReadonly]
  );

  return (
    <Box sx={{overflow: "auto"}} height={'100%'} paddingX={10}>
      <Grid container alignContent='flex-start'>
        <Grid item xs={12} paddingY={4} paddingX={2}>
          <Typography variant="h4">Kompetenzpunkte</Typography>
        </Grid>

        <Grid container item xs={12} paddingY={2} paddingX={2}>
          <Grid container item xs flexDirection="column">
            <Grid item>
              <Typography variant="h6">Insgesamt {allNodes?.length || 0} Kompetenzpunkte</Typography>
            </Grid>

            <Grid container item justifyContent="start">
              {
                Object.entries(NodeLabelIconData).map(([text, color]) => (
                  <LabelNotice color={color} text={text}/>
                ))
              }
            </Grid>
          </Grid>

          <Grid container item xs spacing={1} justifyContent="end" alignItems="center">
            <Grid item>
              <Button
                variant={includeReadonly ? "outlined" : "contained"}
                color="secondary"
                startIcon={<FilterList/>}
                onClick={() => setIncludeReadonly(!includeReadonly)}
              >
                Filter:
                {
                  includeReadonly ? " IN READONLY" : " EX READONLY"
                }
              </Button>
            </Grid>

            <Grid item>
              <SortMenu sortType={sortType} setSortType={setSortType} choices={SortTypes}/>
            </Grid>

            <Grid item>
              <Box
                sx={{
                  background: "rgba(213,169,238,0.6)",
                  borderRadius: "6px",
                }}
                paddingLeft={1}
                paddingRight={2}
              >
                <IconButton disableRipple>
                  <Search/>
                </IconButton>

                <InputBase
                  placeholder="Suche Kompetenzpunkte"
                  value={searchString}
                  onChange={e => setSearchString(e.target.value)}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container paddingY={2} spacing={2}>
          {
            loading ?
              (
                <Grid item display="flex" justifyContent="center" xs={12}>
                  <CircularProgress/>
                </Grid>
              )
              :
              allNodes.length == 0 ?
                <Grid item display="flex" justifyContent="center" xs={12}
                      sx={{
                        borderStyle: "dashed",
                        borderWidth: 1,
                        borderRadius: 20
                      }}
                >
                  <Typography variant="h5" pb={2}>Keine Kompetenzpunkte</Typography>
                </Grid>
                :
                (
                  <>
                    {
                      allNodes?.map((node) => (
                        <Grid item key={`gridItem_${node.id}`}
                              height="120px"
                              xs={12}
                        >
                          <NodeCard node={node}/>
                        </Grid>
                      ))
                    }
                  </>
                )
          }
        </Grid>

      </Grid>
    </Box>
  );
})

function LabelNotice(props: {
  color: string,
  text: string
}) {
  return (
    <>
      <div style={{
        display: "flex",
        alignItems: "center",
        marginRight: 20,
      }}>
        <div style={{
          width: 10,
          height: 10,
          borderRadius: 1000,
          background: props.color,
          marginRight: 10,
        }}/>

        <div>
          {props.text}
        </div>
      </div>
    </>
  )
}