import {Grid, IconButton, ListItem, ListItemIcon, ListItemText, Tooltip, Typography} from "@mui/material";
import {ContentCopy} from "@mui/icons-material";

import {useStore} from "../../../../app/stores/store";

import StatementRow from "../StatementRow";
import {NodeData} from "../../../../app/models/nodeData";

import ChooseLearningGoalModal from "./ChooseLearningGoalModal";
import {observer} from "mobx-react-lite";
import {toast} from "react-toastify";
import AddButton from "../../../../app/common/form/AddButton";
import {ListWithBorders} from "../../../../app/common/ListWithBorders";


export default observer(function LearningGoalsSection() {

  const {nodeStore, modalStore, accountStore} = useStore()
  const {selectedNode} = nodeStore
  const {user} = accountStore

  function onAddNewStatement() {
    modalStore.openModal(<ChooseLearningGoalModal learningGoals={selectedNode?.learningGoals || []}/>)
  }
  
  const readonly = user?.organization.id !== selectedNode?.organizationId;

  return (<Grid container direction={"column"}>
    <Grid item container xs={1} spacing={2}>
      <Grid item xs={3}>
        <Typography variant="h5" color={'primary.light'}>Lernziele</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography variant="h5" color={'primary.light'}>Lernzielsätze</Typography>
      </Grid>
    </Grid>
    <Grid item container xs={10} spacing={2}>
      <Grid item xs={3}>
        {selectedNode ? <LearningGoalsList node={selectedNode}/> : <></>}
      </Grid>
      <Grid item xs={9}>
        <ListWithBorders height={244} dense sx={{overflow: 'auto',}}>
          {selectedNode?.learningGoals.map(goal => {
            return goal.learningGoalStatements.map(statement => {
              return <StatementRow statement={statement} goal={goal} key={`sr_${statement.id}`} readonly={readonly}/>
            })
          })}

        </ListWithBorders>
      </Grid>
    </Grid>
    <Grid item container xs={1} justifyContent={'flex-end'}>
      {
        !readonly &&
          <AddButton onClick={onAddNewStatement}/>
      }
    </Grid>
  </Grid>);
})

interface LearningGoalsListParams {
  node: NodeData
}

function LearningGoalsList({node}: LearningGoalsListParams) {
  return (<ListWithBorders height={244} dense sx={{overflow: 'auto',}}>
    {node.learningGoals.map(el => {
      return <ListItem
        key={`li_${el.id}`}
        divider
        secondaryAction={
          <IconButton edge="end" aria-label="copy_id" onClick={() => {
            navigator.clipboard.writeText(el.id)
            toast.info('Id kopiert')
          }}>
            <Tooltip title={'Learnziel Id kopieren'}>
              <ContentCopy/>
            </Tooltip>
          </IconButton>
        }
      >
        <ListItemIcon>
          <Typography variant="h6">{el.competenceLevel?.number}</Typography>
        </ListItemIcon>
        <ListItemText
          sx={{mr: 16}}
          primary={el.competenceLevel?.title}
          secondary={el.id.substring(0, 8) + '...'}
          onClick={() => {
            navigator.clipboard.writeText(el.id)
          }}
        />
      </ListItem>
    })}
  </ListWithBorders>);
}