import {router} from "../../app/router/Routes";

import {Lock} from "@mui/icons-material";
import {Box, Card, CardActions, CardContent, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import {Board} from "../../app/models/board";

interface Params {
  board: Board
  setSelectedBoard: (board: Board) => void
}

function BoardCard({board, setSelectedBoard}: Params) {

  const navigateToBoardsGraph = () => {
    setSelectedBoard(board)
    router.navigate('/graphEditor')
  }

  return (
    <Card
      sx={{
        height: "100%",
        width: "100%",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        background: "rgba(246,231,255,0.63)",
        cursor: board.readonly ? "default" : "pointer"
      }}
      variant="outlined"
      onClick={() => !board.readonly && router.navigate(`graphEditor/${board.id}`)}
    >
      <CardContent sx={{flex: "1"}}>
        <Box maxWidth="400px">
          <Typography variant="caption" color="#b26500">
            {
              formatDate(board.modifiedOn)
            }
          </Typography>

          <Typography variant="h6" paddingY={1} noWrap>
            {board.title}
          </Typography>

          <Typography variant="body2" color="text.secondary" noWrap>
            {board.description || "N/A"}
          </Typography>
        </Box>
      </CardContent>
      <CardActions disableSpacing sx={{flex: "0"}}>
        <Grid container justifyContent='end'>
          {
            board.readonly ?
              <Tooltip title={"Readonly board"}>
                <IconButton disableRipple>
                  <Lock/>
                </IconButton>
              </Tooltip> :
              <></>
          }
        </Grid>
      </CardActions>
    </Card>
  );
}

function formatDate(date: Date | string) {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  
  const yyyy = String(date.getFullYear()).padStart(4, '0');
  const MM = String(date.getMonth() + 1).padStart(2, '0');
  const dd = String(date.getDate()).padStart(2, '0');
  const hh = String(date.getHours()).padStart(2, '0');
  const mm = String(date.getMinutes()).padStart(2, '0');

  return `${yyyy}.${MM}.${dd} ${hh}:${mm}`;
}

export default BoardCard;