import {Button, Grid, Tooltip, Typography} from '@mui/material';

import {grey} from '@mui/material/colors';
import DownloadButton from './DownloadButton';
import {Save} from '@mui/icons-material';
import {Board} from "../../app/models/board";

interface Props {
    saveGraph: Function
    board: Board
}

export default function BottomBar({saveGraph, board}: Props) {

    const onDragStart = (event: any, nodeType: any) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <Grid container>
            <Grid item container xs={10} spacing={2} alignItems={'center'}>
                {
                    board.readonly ?
                        <>
                            <Grid item>
                                <Typography color={grey[500]}>Dieser Graph ist schreibgeschützt.</Typography>
                            </Grid>
                        </>
                        :
                        <>
                            <Grid item>
                                <Tooltip title="Drag and Drop" placement="right-end" enterDelay={1000}>
                                    <div className="dndnode" style={{marginBottom: 0}}
                                         onDragStart={(event) => onDragStart(event, 'default')} draggable>
                                        Neue Kompetenzbasis einblenden
                                    </div>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Typography color={grey[500]}>Zum Ausblenden/Löschen: Auswählen und Rücktaste oder
                                    Delete-Taste drücken</Typography>
                            </Grid>
                            <Grid item>
                                <Button startIcon={<Save></Save>} variant='outlined' onClick={() => saveGraph()}>Änderungen
                                    Speichern</Button>
                            </Grid>
                        </>
                }
            </Grid>
            <Grid item container xs={2} justifyContent={'flex-end'} alignItems={'center'}>
                <DownloadButton/>
            </Grid>
        </Grid>
    );
};