import { useEffect, useState } from "react";
import { Button, IconButton, ListItem, ListItemText, Stack, TextField, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useStore } from "../../../../app/stores/store";
import { toast } from "react-toastify";
import { ListWithBorders } from "../../../../app/common/ListWithBorders";

export default function AltTermsEditor() {

    const { nodeStore, modalStore } = useStore()
    const { selectedNode, saveAltTerms } = nodeStore

    const [altTerms, setAltTerms] = useState<string[]>(selectedNode?.altTerms ? JSON.parse(selectedNode?.altTerms) : []);
    const [newAltTerm, setNewAltTerm] = useState<string>('');
    const [isDirty, setIsDirty] = useState<boolean>(false);

    useEffect(() => {
        const altTermsString = JSON.stringify(altTerms);
        const selectedNodeAltTerms = selectedNode?.altTerms || '';
        setIsDirty(altTermsString !== selectedNodeAltTerms);
    }, [altTerms, selectedNode]);

    const handleAdd = () => {
        if (newAltTerm.trim().length < 4) return toast.error('Mind. 4 Zeichen eingeben')
        if (altTerms.includes(newAltTerm)) return toast.error('Identischer Term ist bereits vorhanden')
        setAltTerms([newAltTerm.trim(), ...altTerms]);
        setNewAltTerm('');

    };

    const handleDelete = (index: number) => {
        const updatedAltTerms = [...altTerms];
        updatedAltTerms.splice(index, 1);
        setAltTerms(updatedAltTerms);
    };

    const handleSave = () => {
        if (selectedNode) saveAltTerms(selectedNode, JSON.stringify(altTerms))
        modalStore.closeModal()
    }

    const handleDiscard = () => {
        setAltTerms(selectedNode?.altTerms ? JSON.parse(selectedNode.altTerms) : []);
        setNewAltTerm('');
        setIsDirty(false);
        modalStore.closeModal();
    };

    return (<Stack spacing={2}>
        <Typography color={'primary.light'} variant="h5">Alternative Terme</Typography>
        <TextField
            label="Alt. Term hinzufügen durch Enter (mind. 4 Zeichen eingeben)"
            value={newAltTerm}
            onChange={(e) => setNewAltTerm(e.target.value)}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    handleAdd();
                }
            }}
        />
        <ListWithBorders height='226px' dense>
            {altTerms.map((str, index) => (
                <ListItem key={index} divider
                    secondaryAction={<IconButton edge="end" aria-label="delete"
                        sx={{
                            ':hover': {
                                color: 'error.main',
                            },
                        }}
                        onClick={() => { handleDelete(index) }}
                    >
                        <Delete />
                    </IconButton>}>
                    <ListItemText primary={str.length > 200 ? `${str.slice(0, 200)}...` : str} />
                </ListItem>
            ))}
        </ListWithBorders>
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={2}>
            <Button disabled={!isDirty} onClick={handleDiscard}>Verwerfen</Button>
            <Button disabled={!isDirty} onClick={handleSave} variant="outlined">Liste Speichern</Button>
        </Stack>
    </Stack>);
}