import * as React from 'react';
import {KeyboardDoubleArrowRight} from '@mui/icons-material';
import {Tabs, Tab, Typography, Box, ListItem, IconButton, ListItemText} from '@mui/material';
import {router} from '../../../app/router/Routes';
import {NodeData} from '../../../app/models/nodeData';
import AltTermsList from './AltTerms/AltTermsList';
import {ListWithBorders} from '../../../app/common/ListWithBorders';
import {useStore} from '../../../app/stores/store';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{paddingX: 0, marginTop: 2}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface TabsSectionProps {
    node: NodeData | null
}

export default function TabsSection({node}: TabsSectionProps) {

    const {accountStore, nodeStore} = useStore();
    const isReadOnly = accountStore.user?.organization.id !== nodeStore.selectedNode?.organizationId
    const [value, setValue] = React.useState(0);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Alt. Terme" {...a11yProps(0)} />
                    <Tab label="Boards" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <AltTermsList/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ListWithBorders height='226px' dense>
                    {node?.boards.map(b => {
                        return <ListItem divider
                                         key={`ListItem_${b.id}`}
                                         secondaryAction={
                                             !isReadOnly &&
                                             <IconButton color='primary' edge="end" onClick={() => {
                                                 router.navigate(`/boards/${b.id}`)
                                             }}>
                                                 <KeyboardDoubleArrowRight/>
                                             </IconButton>
                                         }
                        >
                            <ListItemText primary={b.title.length > 200 ? `${b.title.slice(0, 190)}...` : b.title}/>
                        </ListItem>
                    })}
                </ListWithBorders>
            </TabPanel>
        </Box>
    );
}