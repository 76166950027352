import {LabelOutlined} from "@mui/icons-material";
import {NodeType} from "../../app/models/nodeData";

export const NodeLabelIconData = {
  [NodeType.Principle]: "#30baff",
  [NodeType.Concept]: "#f54ec6",
  [NodeType.Procedure]: "#4ef5aa",
}

function LabelIcon(props: {
  color: string,
}) {
  return (
    <>
      <div style={{
        width: 50,
        height: 50,
        borderRadius: 1000,
        background: props.color,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        <LabelOutlined sx={{
          color: "#fff"
        }}/>
      </div>
    </>
  )
}

export default LabelIcon;