import {createContext, useContext} from "react";
import CommonStore from "./commonStore";
import AccountStore from "./accountStore";
import ModalStore from "./modalStore";
import GraphStore from "./graphStore";
import NodeStore from "./nodeStore";
import BoardStore from "./boardStore";
import OrganizationStore from "./organizationStore";
import UserStore from "./userStore";
import UserManagerStore from "./userManagerStore";
import TranslationStore from "./translationStore";

interface Store {
    commonStore: CommonStore;
    accountStore: AccountStore;
    modalStore: ModalStore;
    graphStore: GraphStore;
    nodeStore: NodeStore;
    boardStore: BoardStore;
    orgaStore: OrganizationStore;
    userStore: UserStore,
    userManagerStore: UserManagerStore,
    translationStore: TranslationStore,
}

export const store: Store = {
    commonStore: new CommonStore(),
    accountStore: new AccountStore(),
    modalStore: new ModalStore(),
    graphStore: new GraphStore(),
    nodeStore: new NodeStore(),
    boardStore: new BoardStore(),
    orgaStore: new OrganizationStore(),
    userStore: new UserStore(),
    userManagerStore: new UserManagerStore(),
    translationStore: new TranslationStore(),
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}