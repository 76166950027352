import { useState } from "react";

import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

//@ts-ignore
import ImageResize from 'quill-image-resize';

import katex from "katex";
import "katex/dist/katex.min.css";
import { Button, Grid, Typography } from "@mui/material";
import { useStore } from "../../stores/store";

//@ts-ignore
window.katex = katex;
Quill.register('modules/imageResize', ImageResize);

interface Params {
    incContent: string,
    header?: string,
    readOnly?: boolean,
    onSave?: (content: string) => void,
}

export default function TextEditor({ incContent, header = '', readOnly = false, onSave = () => { } }: Params) {

    const { modalStore } = useStore()

    const [content, setContent] = useState(incContent)
    const [isDirty, setIsDirty] = useState(false)
    const modules = getModules(readOnly)

    const handleChange = (value: string) => {
        setContent(value);
        if (value !== incContent) {
            setIsDirty(true);
        } else {
            setIsDirty(false);
        }
    };

    return (
        <>
            {header ? <Typography variant="h5" color={'primary.main'}>{header}</Typography> : null}
            <ReactQuill readOnly={readOnly} value={content} onChange={handleChange} modules={modules}
                style={{ height: readOnly ? '96%' : header ? 'calc(100% - 104px)' : 'calc(100% - 84px)' }} />
            {!readOnly && <Grid container justifyContent={'flex-end'}>
                <Button disabled={!isDirty}  sx={{ mt: '56px' }} onClick={modalStore.closeModal}>Verwerfen</Button>
                <Button disabled={!isDirty} variant="outlined" sx={{ mt: '56px', ml:'28px' }} onClick={() => onSave(content)}>Speichern</Button>
            </Grid>}
        </>
    );
}

function getModules(readOnly: boolean) {
    if (readOnly) return { toolbar: false }
    return {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image'],
            ['formula'],
        ],
        imageResize: {},
    };
}