import {NavLink, useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";

import {Box, Container, Grid, Typography} from "@mui/material";

import {useStore} from "../../app/stores/store";
import StaticReactFlowEditor from "./StaticReactFlowEditor";
import NodeDetails from "./NodeDetails";
import {useEffect} from "react";

export default observer(function GraphEditorScreen() {

  const {id} = useParams();
  const {boardStore, nodeStore, graphStore} = useStore();

  useEffect(() => {
    if (id) {
      boardStore.clearSelectedBoard()
      boardStore.loadBoard(id)
    }

    nodeStore.loadNodeTypes();
    graphStore.loadEdgeTypes()
  }, [nodeStore, graphStore, id]);

  return (
    <Box height={'100%'}>
      <Grid container direction='column'>
        <Grid item xs={8}>
          <Typography variant="h4">Graph Editor
            - {boardStore.loading ? "Loading" : (boardStore.selectedBoard?.title || 'Kein Board ausgewählt')} </Typography>
        </Grid>
        {
          boardStore.selectedBoard ? (
            <Grid item container>
              <Grid item xs={10}>
                <Container sx={{height: 'calc(100vh - 100px)', width: '100%'}} maxWidth={false}>
                  <StaticReactFlowEditor/>
                </Container>
              </Grid>
              <Grid item xs={2}>
                <NodeDetails/>
              </Grid>
            </Grid>
          ) : (
            <>
              <NavLink style={{paddingTop: 10}} to={'/boards'}>
                <Typography variant="h4">Zu den Boards</Typography>
              </NavLink>
            </>
          )}
      </Grid>
    </Box>
  );
})