import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectProps } from '@mui/material/Select';

import { useField, useFormikContext } from "formik"

interface Props {
    name: string
    options: { text: string; value: string; }[]
    label: string
    size?: "small" | "medium"
}

export default function SelectMuiFormik({name, options, label='', size='medium'}: Props) {

    const { setFieldValue } = useFormikContext();
    const [field, meta]=useField(name)

    if(!field.value) field.value=''

    const configSelect: SelectProps={
        ...field
    }

    if(meta && meta.touched && meta.error){
        configSelect.error=true
    }

    return (
        <FormControl fullWidth size={size}>
            <InputLabel id={`label_${name}`}>{label}</InputLabel>
            <Select
                labelId={`label_${name}`}
                id={`select_${name}`}
                value={field.value}
                label={label}
                {...configSelect}
                onChange={(e) => {
                    setFieldValue(name, e.target.value);
                }}
            >
                {options.map((option, i) => (
                    <MenuItem
                    key={`menuitem_${i}_${option.value}`}
                    value={option.value}
                    >
                        {option.text}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
  );
}