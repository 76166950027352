import { Button, Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Edge } from "reactflow";
import { useStore } from "../../app/stores/store";

interface Props {
    edge: Edge
    updateEdge: Function
}

export default function ChooseEdgeTypeModal({ edge, updateEdge }: Props) {

    const { graphStore } = useStore();
    const { edgeTypes } = graphStore;

    const [type, setType] = useState(edge.label?.toString() || '?');

    const handleChange = (event: SelectChangeEvent) => {
        setType(event.target.value as string);
    };

    return (
        <Stack spacing={2}>
            <Typography variant="h4">Relation ändern</Typography>
            <Typography variant="h5">{ }</Typography>
            <Typography variant="h5">{ }</Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Typ</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    label="Typ"
                    onChange={handleChange}
                >
                    {edgeTypes.map(opt => {
                        return <MenuItem key={opt.abbreviation} value={opt.abbreviation}>{opt.abbreviation} - {opt.title}</MenuItem>
                    })}
                </Select>
            </FormControl>
            <Divider />
            <Button variant="outlined" onClick={(event) => updateEdge(edge, type, event)}>Bestätigen</Button>
        </Stack>
    );

};
