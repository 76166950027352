import {useParams} from "react-router-dom";
import {useStore} from "../../../app/stores/store";
import {useEffect, useState} from "react";

import {observer} from 'mobx-react-lite';


import {Box, CircularProgress, Divider, Grid, IconButton, Stack, Typography} from "@mui/material";
import {Delete, Edit} from '@mui/icons-material';

import NodeDetailsForm from "./NodeDetailsForm";
import TextEditor from "../../../app/common/form/TextEditor";
import ConfirmModal from "../../../app/common/modals/ConfirmModal";
import {router} from "../../../app/router/Routes";
import {toast} from "react-toastify";
import LearningGoalsSection from "./learningGoals/LearningGoalsSection";
import TabsSection from "./TabsSection";


export default observer(function NodeDetailsScreen() {

  const {nodeStore, modalStore, graphStore, accountStore} = useStore()
  const {selectedNode, loadNode, loading, updateRichDescription, deleteNode} = nodeStore

  const [isNew, setIsNew] = useState(false)
  const [header, setHeader] = useState('')

  const {user} = accountStore;

  const readonly = user?.organization.id !== selectedNode?.organizationId;

  const {id} = useParams();

  useEffect(() => {
    nodeStore.loadNodeTypes();
    nodeStore.loadCompetenceLevels();
    graphStore.loadEdgeTypes();
  }, [nodeStore, graphStore]);

  useEffect(() => {
    if (id && id !== 'new') loadNode(id)
    if (id === 'new') setIsNew(true)
  }, [id, loadNode, setIsNew])

  useEffect(() => {
    setHeader(getHeader(selectedNode?.title || ''))
  }, [selectedNode?.title])

  function handleUpdateRichDescription(content: string) {
    if (!selectedNode) return
    updateRichDescription(selectedNode?.id, content)
    modalStore.closeModal()
    toast.success('Beschreibung gespeichert')
  }

  function getHeader(title: string) {
    if (!title || title.length === 0) return 'Kein Titel'
    if (title.length > 50) return title.substring(0, 50) + '...'
    return title
  }

  function handleNodeDelete() {
    if (!selectedNode) return
    deleteNode(selectedNode?.id)
    modalStore.closeModal()
    router.navigate('/nodes')
  }

  return (
    <>
      {loading ? <Box sx={{height: 763}}><CircularProgress/></Box> :
        <Grid container spacing={2}>
          <Grid container item xs={7} alignItems={'flex-start'}>
            <Grid item xs={10}>
              <Typography variant="h4" color={'primary'}>{header}</Typography>
            </Grid>
            <Grid container item xs={2} justifyContent={'flex-end'}>
              {
                !readonly &&
                  <IconButton color="default" sx={{
                    ':hover': {color: 'error.main'},
                  }}
                              onClick={() => modalStore.openModal(<ConfirmModal title="Kompetenzbasis löschen"
                                                                                text={`Die Kompetenzbasis ist noch auf ${selectedNode?.boards.length} Board/s eingeblendet. Wirklich löschen?`}
                                                                                onConfirm={() => handleNodeDelete()}
                                                                                onCancel={() => modalStore.closeModal()}/>, 'mini')}
                  >
                      <Delete fontSize={'large'}></Delete>
                  </IconButton>
              }
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item container xs={6}>
                <Grid item xs={12}>
                  <Typography variant="h5" color={'primary.light'}>Kompetenzbasis</Typography>
                </Grid>
                <Grid item xs={12} pt={1}>
                  <NodeDetailsForm isNew={isNew} setIsNew={setIsNew} getHeader={getHeader} setHeader={setHeader}/>
                </Grid>
              </Grid>
              <Grid item container xs={6} alignItems={'strech'}>
                <Stack spacing={2} width={'100%'}>
                  <TabsSection node={selectedNode}/>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
              <Grid item xs={12}>
                <Box height={320}>
                  <LearningGoalsSection/>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={5}>
            <Grid item xs={12} sx={{maxWidth: '100%'}}>
              <Box sx={{
                height: 700,
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
                overflowY: "scroll",
                p: 2
              }}>
                <div style={{wordWrap: "break-word"}}
                     dangerouslySetInnerHTML={{__html: addMaxWidthToImages(selectedNode?.richDescription || '...kein Inhalt')}}/>
              </Box>
              {/* TODO: reconsider to bring back TextEditor Component instead of div <TextEditor incContent={selectedNode?.richDescription || ''} readOnly /> */}
            </Grid>
            <Grid container item xs={12} justifyContent={'flex-end'}>
              {
                !readonly &&
                  <IconButton color="default" sx={{
                    ':hover': {
                      color: 'primary.main',
                    },
                  }}
                              onClick={() => modalStore.openModal(
                                <Box sx={{height: '680px'}}>
                                  <TextEditor incContent={selectedNode?.richDescription || ''}
                                              onSave={handleUpdateRichDescription}/>
                                </Box>
                                , 'small')}
                  >
                      <Edit fontSize={'large'}></Edit>
                  </IconButton>
              }
            </Grid>
          </Grid>
        </Grid>
      }
    </>);
})

function addMaxWidthToImages(htmlString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const images = doc.querySelectorAll('img');

  images.forEach(image => {
    image.style.maxWidth = '100%';
  });

  return doc.documentElement.outerHTML;
}