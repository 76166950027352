import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import {Grid} from '@mui/material';
import {Edit} from '@mui/icons-material';

import {observer} from 'mobx-react-lite';
import {useStore} from '../../../../app/stores/store';

import AltTermsEditor from './AltTermsEditor';
import {ListWithBorders} from '../../../../app/common/ListWithBorders';

export default observer(function AltTermsList() {
  const {modalStore, nodeStore, accountStore} = useStore()
  const {selectedNode} = nodeStore
  const {user} = accountStore

  const altTermsArr: string[] = selectedNode?.altTerms ? JSON.parse(selectedNode?.altTerms) : []

  return (
    <div>
      <ListWithBorders height='226px' dense>
        {altTermsArr.map((str, index) => (
          <ListItem key={index} divider>
            <ListItemText primary={str.length > 200 ? `${str.slice(0, 190)}...` : str}/>
          </ListItem>
        ))}
      </ListWithBorders>
      <Grid container justifyContent={'flex-end'} mt={1.4}>
        {
          (user?.organization.id === selectedNode?.organizationId) &&
            <IconButton color="default" sx={{
              ':hover': {
                color: 'primary.main',
              },
            }}
                        onClick={() => {
                          modalStore.openModal(<AltTermsEditor/>, 'small')
                        }}
            >
                <Edit fontSize={'large'}></Edit>
            </IconButton>
        }
      </Grid>
    </div>
  );
});