import {Board} from "./board";
import {LearningGoal} from "./learningGoal";
import {NodeEdgeInfo} from "./nodeEdgeInfo";

export enum NodeType {
  Principle = "Principle",
  Concept = "Concept",
  Procedure = "Procedure",
}

export interface NodeData {
  id: string,
  title: string,
  shortDescription: string,
  richDescription: string,
  type: string,
  organizationTitle: string,
  organizationId?: string,
  isDeleted: boolean,
  boards: Board[],
  edges: NodeEdgeInfo[],
  learningGoals: LearningGoal[],
  altTerms: string
}

export class NodeData implements NodeData {
  constructor(init?: NodeData) {
    Object.assign(this, init);
  }
}