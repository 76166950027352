import {Tooltip} from '@mui/material';
import {CSSProperties} from 'react';

import {getBezierPath, Position} from 'reactflow';

import './buttonEdge.css';
import {Style} from "node:util";


const foreignObjectSize = 40;

interface Props {
  id: string
  sourceX: number,
  sourceY: number,
  targetX: number,
  targetY: number,
  sourcePosition: Position,
  targetPosition: Position,
  label: string,
  style: CSSProperties
}

export default function CustomEdge(interactable: boolean, {
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  label,
}: Props) {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const buttonBorderClass = getButtonsBorderClassName(label)

  //used to offset the button on the edge to prevent exactly overlaying buttons
  const buttonOffset = getButtonOffset(sourceY, targetY)

  return (
    <>
      <path
        id={id}
        className={'react-flow__edge-path stroke-width-2'}
        d={edgePath}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={labelX - buttonOffset - foreignObjectSize / 2}
        y={labelY - buttonOffset - foreignObjectSize / 2}
        className="edgebutton-foreignobject"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div unselectable='on'>
          {
            interactable ?
              <Tooltip title="Doppelklick zum Bearbeiten" enterDelay={1000}>
                <button className={`edgebutton ${buttonBorderClass}-border`}>
                  {label || '?'}
                </button>
              </Tooltip> :
              <button className={`edgebutton ${buttonBorderClass}-border`}>
                {label || '?'}
              </button>
          }
        </div>
      </foreignObject>
    </>
  );
}

const labelToColor: any = {
  S: {
    colorName: 'purple',
    colorCode: '#9c27b0'
  },
  C: {
    colorName: 'blue',
    colorCode: '#03a9f4'
  },
  R: {
    colorName: 'pink',
    colorCode: '#e91e63'
  },
  P: {
    colorName: 'green',
    colorCode: '#8bc34a'
  },
  IP: {
    colorName: 'amber',
    colorCode: '#ffc107'
  }
}

const getButtonsBorderClassName = (label: string): string => {
  var borderColor = 'grey'

  if (labelToColor[label] && labelToColor[label]['colorName']) {
    borderColor = labelToColor[label]['colorName']
  }

  return borderColor
}

const getButtonOffset = (sourceY: number, targetY: number): number => {
  var offset = 15
  if (targetY > sourceY) offset = -15

  return offset
}