import React, {useState} from "react";
import {Button, Menu} from "@mui/material";
import {Sort} from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";

export default function <TSortType>(props: {
  sortType: TSortType,
  setSortType: React.Dispatch<React.SetStateAction<TSortType>>,
  choices: Array<{
    type: TSortType,
    title: string,
  }>
}) {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseFactory = (choice?: TSortType) => {
    return () => {
      choice && props.setSortType(choice);

      setAnchorEl(null);
    }
  };

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<Sort/>}
        onClick={handleClick}
      >
        Sortierung

        {
          props.sortType != "none" &&
          `: ${props.choices.find(value => value.type == props.sortType)?.title}`
        }
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        onClose={handleCloseFactory()}
      >
        {
          props.choices.map(value => (
            <MenuItem
              disabled={value.type == props.sortType}
              onClick={handleCloseFactory(value.type)}
            >
              {value.title}
            </MenuItem>
          ))
        }
      </Menu>
    </>
  )
}