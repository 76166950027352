import { useEffect, useState } from "react";
import { useStore } from "../../../app/stores/store";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Box, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import ConfirmModal from "../../../app/common/modals/ConfirmModal";
import { Delete } from "@mui/icons-material";
import BoardDetailsForm from "./BoardDetailsForm";
import { router } from "../../../app/router/Routes";
import NavigationButton from "../../../app/common/NavigationButton";

export default observer(function BoardDetailsScreen() {

    const { boardStore, modalStore } = useStore()
    const { selectedBoard, loading, loadBoard, deleteBoard, clearSelectedBoard } = boardStore;

    const [isNew, setIsNew] = useState(false)
    const [header, setHeader] = useState('')

    const { id } = useParams();

    useEffect(() => {
        if (id && id !== 'new') loadBoard(id)
        if (id === 'new') {
            setIsNew(true)
            clearSelectedBoard()
        }
    }, [id, loadBoard, setIsNew, clearSelectedBoard])

    useEffect(() => {
        setHeader(getHeader(selectedBoard?.title || ''))
    }, [selectedBoard?.title])
    
    function getHeader(title: string) {
        if (!title || title.length === 0) return 'Kein Titel'
        if (title.length > 50) return title.substring(0, 50) + '...'
        return title
    }

    function handleBoardDelete() {
        if (!selectedBoard) return
        deleteBoard(selectedBoard?.id)
        modalStore.closeModal()
        router.navigate('/boards')
    }

    return (<>
        {loading ? <Box sx={{ height: 763 }}><CircularProgress /></Box> :
            <Grid container spacing={2}>
                <Grid container item xs={7} alignItems={'center'}>
                    <Grid item xs={10}>
                        <Typography variant="h4">{header}</Typography>
                    </Grid>
                    {
                        !selectedBoard?.readonly &&
                        <Grid container item xs={2} justifyContent={'flex-end'}>
                            <IconButton disabled={isNew} color="default" sx={{
                                ':hover': {
                                    color: 'error.main',
                                },
                            }}
                                        onClick={() => modalStore.openModal(<ConfirmModal title="Board löschen" text="Board wirklich löschen?" onConfirm={() => handleBoardDelete()} onCancel={() => { modalStore.closeModal() }} />, 'mini')}
                            >
                                <Delete fontSize={'large'}></Delete>
                            </IconButton>
                        </Grid>
                    }
                </Grid>
                <Grid item container xs={7} >
                    <Grid item xs={12} >
                        <Typography variant="h5" color={'primary.light'} >Board</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <BoardDetailsForm isNew={isNew} setIsNew={setIsNew} getHeader={getHeader} setHeader={setHeader} />
                    </Grid>
                    <Grid item xs={12} >
                        <NavigationButton text='Zum Graph Editor' route='/graphEditor' />
                    </Grid>
                </Grid>
            </Grid>
        }
    </>);
})