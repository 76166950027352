import {Edit, Delete, Search} from "@mui/icons-material";
import {Stack, IconButton, Box, ListItemIcon, ListItemText, ListItem, Typography} from "@mui/material";

import TextEditor from "../../../app/common/form/TextEditor";
import ConfirmModal from "../../../app/common/modals/ConfirmModal";

import {LearningGoalStatement} from "../../../app/models/LearningGoalStatement";
import {useStore} from "../../../app/stores/store";
import {LearningGoal} from "../../../app/models/learningGoal";
import {observer} from "mobx-react-lite";

interface Params {
    statement: LearningGoalStatement
    goal: LearningGoal
    readonly: boolean
}

export default observer(function StatementRow({statement, goal, readonly}: Params) {

    const {modalStore, nodeStore} = useStore()

    function handleEditStatement(text: string) {
        nodeStore.updateLearningGoalStatement(statement, text)
        modalStore.closeModal()
    }

    function handleDeleteStatement(statementId: string) {
        nodeStore.deleteLearningGoalStatement(statementId)
        modalStore.closeModal()
    }


    return (<ListItem
        key={`li_${statement.id}`}
        divider
        secondaryAction={
            <Stack direction={'row'} spacing={2} marginLeft={5}>
                <IconButton edge="end" aria-label="details"
                            sx={{
                                transform: 'scaleX(-1)',
                                ':hover': {
                                    color: 'primary.light',
                                },
                            }}
                            onClick={() => {
                                modalStore.openModal(<Box sx={{height: '670px'}}><TextEditor readOnly
                                                                                             incContent={statement.content}
                                                                                             header={`Aussage zu ${goal.competenceLevel.number} - ${goal.competenceLevel.title}`}/></Box>, 'small')
                            }}
                >
                    <Search/>
                </IconButton>
                {
                    !readonly &&
                    <>
                        <IconButton edge="end" aria-label="edit"
                                    sx={{
                                        ':hover': {
                                            color: 'primary.main',
                                        },
                                    }}
                                    onClick={() => {
                                        modalStore.openModal(<Box sx={{height: '670px'}}><TextEditor
                                            incContent={statement.content}
                                            header={`Aussage zu ${goal.competenceLevel.number} - ${goal.competenceLevel.title}`}
                                            onSave={handleEditStatement}/></Box>, 'small')
                                    }}
                        >
                            <Edit/>
                        </IconButton>
                        <IconButton edge="end" aria-label="delete"
                                    sx={{
                                        ':hover': {
                                            color: 'error.main',
                                        },
                                    }}
                                    onClick={() => modalStore.openModal(<ConfirmModal title="Aussage löschen"
                                                                                      text="Möchten Sie die Aussage wirklich löschen?"
                                                                                      onConfirm={() => {
                                                                                          handleDeleteStatement(statement.id)
                                                                                      }}
                                                                                      onCancel={() => modalStore.closeModal()}/>, 'mini')}
                        >
                            <Delete/>
                        </IconButton>
                    </>
                }
            </Stack>
        }
    >
        <ListItemIcon>
            <Typography variant="h6">{goal.competenceLevel.number}</Typography>
        </ListItemIcon>
        <ListItemText
            sx={{mr: 16}}
            primary={htmlToPlainText(statement.content)}
        />
    </ListItem>);
})

function htmlToPlainText(html: string) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    let output = tempElement.textContent || tempElement.innerText || ''

    if (output.length > 220) output = output.substring(0, 210) + '...'

    return output;
}