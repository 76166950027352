import {router} from "../../app/router/Routes";
import {Button, Card, CardContent, Grid, IconButton, Menu, Typography} from "@mui/material";
import {NodeData, NodeType} from "../../app/models/nodeData";
import {Lock, MoreHoriz, Share} from "@mui/icons-material";
import LabelIcon, {NodeLabelIconData} from "./NodeCardLabelIcon";
import {useStore} from "../../app/stores/store";
import MenuItem from "@mui/material/MenuItem";
import {Board} from "../../app/models/board";
import {useState} from "react";

interface Params {
  node: NodeData
}

function NodeCard({node}: Params) {
  const {accountStore} = useStore()
  const {user} = accountStore;

  const readonly = user?.organization.id !== node?.organizationId;

  const navigateToNode = () => {
    router.navigate(`/nodes/${node.id}`)
  }

  return (
    <Card
      sx={{
        height: "100%",
        width: "100%",
        borderRadius: "20px",
        background: "rgba(246,231,255,0.63)",
      }}
      variant="outlined"
    >
      <CardContent>
        <Grid container>
          <Grid item pr={1} alignItems="center">
            <LabelIcon color={NodeLabelIconData[node.type as NodeType]}/>
          </Grid>

          <Grid item xs>
            <Typography variant="h6" paddingBottom={1} sx={{cursor: "pointer"}} onClick={navigateToNode}>
              {shortenString(node.title, 40)}

              {
                readonly &&
                  <IconButton size="small">
                      <Lock fontSize="small"/>
                  </IconButton>
              }
            </Typography>

            <Typography variant="body2" color="text.secondary">
              {shortenString(node.shortDescription, 70) || "N/A"}
            </Typography>
          </Grid>

          <Grid container item xs justifyContent="end" alignItems="center">
            {
              node.boards?.slice(0, 2).map(board => (
                <Grid item pl={1}>
                  <Button
                    size="small"
                    startIcon={<Share/>}
                    variant="contained"
                    onClick={() => router.navigate(`/boards/${board.id}`)}
                  >
                    <Typography fontSize="smaller">
                      {shortenString(board.title, 15)}
                    </Typography>
                  </Button>
                </Grid>
              ))
            }

            {
              node.boards.length > 2 &&
                <Grid item pl={1}>
                    <MoreBoards boards={node.boards}/>
                </Grid>
            }
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function shortenString(str: string, maxLen: number = 100) {
  if (str.length > maxLen) {
    return str.substring(0, maxLen) + "..."
  }

  return str
}

function MoreBoards(props: {
  boards: Board[]
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="small"
        color="primary"
        onClick={handleClick}
      >
        <MoreHoriz/>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        onClose={handleClose}
      >
        {
          props.boards?.map(board => (
            <MenuItem
              onClick={() => {
                router.navigate(`/boards/${board.id}`);
                handleClose()
              }}
            >
              {shortenString(board.title, 20)}
            </MenuItem>
          ))
        }
      </Menu>
    </>
  )
}

export default NodeCard;