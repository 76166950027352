import { useReactFlow, getRectOfNodes, getTransformForBounds } from 'reactflow';
import { toSvg } from 'html-to-image';
import { Button } from '@mui/material';
import { useStore } from '../../app/stores/store';
import sanitize from 'sanitize-filename';
import { Download } from '@mui/icons-material';

function downloadImage(dataUrl: any, fileName: string) {
    fileName = sanitize(fileName)
    const currentDate = new Date()
    const formattedDateStr = `${currentDate.getFullYear()}${currentDate.getMonth() > 10 ? '0' + currentDate.getMonth() : currentDate.getMonth()}${currentDate.getDate()}-${currentDate.getHours()}${currentDate.getMinutes()}${currentDate.getSeconds()}`;

    const a = document.createElement('a');
    a.setAttribute('download', `${fileName}-${formattedDateStr}.svg`);
    a.setAttribute('href', dataUrl);
    a.click();
}

function DownloadButton() {
    const { getNodes } = useReactFlow();
    const store = useStore();
    const { boardStore } = store;

    const handleClick = () => {
        var reactFlowComponent = document.querySelector('.react-flow__viewport') as HTMLElement;
        if (reactFlowComponent == null) return

        const nodesBounds = getRectOfNodes(getNodes());

        const maxXValue = Math.max(...getNodes().map(n => n.position.x))
        const minXValue = Math.min(...getNodes().map(n => n.position.x))
        const maxYValue = Math.max(...getNodes().map(n => n.position.y))
        const minYValue = Math.min(...getNodes().map(n => n.position.y))
        const width = (maxXValue + Math.abs(minXValue)) * 1.2
        const height = (maxYValue + Math.abs(minYValue)) * 1.2

        const transform = getTransformForBounds(nodesBounds, width, height, 0.5, 2);

        toSvg(reactFlowComponent, {
            width: width,
            height: height,
            style: {
                width: width.toString(),
                height: height.toString(),
                transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
            },
        }).then((dataUrl) => downloadImage(dataUrl, `Cokomo_Graph-${boardStore.selectedBoard?.title}`));
    };

    return (
        <Button variant='outlined' startIcon={<Download></Download>} onClick={handleClick}>
            Download
        </Button>
    );
}

export default DownloadButton;
