import {Grid} from "@mui/material";
import {Form, Formik} from "formik";
import {toast} from "react-toastify";
import {observer} from "mobx-react-lite";
import * as Yup from 'yup';

import {useStore} from "../../../app/stores/store";
import {NodeData} from "../../../app/models/nodeData";

import TextInputMuiFormik from "../../../app/common/form/muiFormikComponents/TextInputMuiFormik";
import SelectMuiFormik from "../../../app/common/form/muiFormikComponents/SelectMuiFormik";
import SubmitButtonMuiFormik from "../../../app/common/form/muiFormikComponents/SubmitButtonMuiFormik";

interface Params {
  isNew: boolean,
  setIsNew: React.Dispatch<React.SetStateAction<boolean>>,

  getHeader(title: string): string,

  setHeader: React.Dispatch<React.SetStateAction<string>>,
}

export default observer(function NodeDetailsForm({isNew, setIsNew, getHeader, setHeader}: Params) {

  const {nodeStore, accountStore} = useStore()
  const {nodeTypes, selectedNode, createNode, updateBaseData} = nodeStore
  const {user} = accountStore

  const readonly = user?.organization.id !== selectedNode?.organizationId;

  async function handleFormSubmit(node: NodeData, {resetForm}: any) {
    try {
      if (readonly) {
        throw new Error("You can't edit/delete this node");
      }

      if (isNew) {
        await createNode(node)
        setIsNew(false)
      } else {
        await updateBaseData(node)
      }
      resetForm({values: node, dirty: false})
      setHeader(getHeader(node.title))
      toast.success('Gespeichert')
    } catch (error) {
      console.error(error)
      toast.error('Etwas lief schief: ' + error)
    }
  }

  const validationSchema = Yup.object({
    title: Yup.string().required('Bitte einen Titel vergeben').min(4, 'Zwecks späterer Suche, mindestens 4 Buchstaben eingeben.'),
    type: Yup.string().required('Bitte einen Typ auswählen')
  })

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={selectedNode ? selectedNode : new NodeData()}
      onSubmit={handleFormSubmit}
    >
      <Form>
        <Grid container spacing={2}>
          <Grid container item justifyContent='stretch'>
            <TextInputMuiFormik
              name='title'
              label="Titel"
              disabled={readonly}
            />
          </Grid>
          <Grid container item justifyContent='stretch'>
            {
              readonly ?
                <TextInputMuiFormik
                  name='type'
                  label="Typ"
                  disabled={readonly}
                /> :
                <SelectMuiFormik
                  options={nodeTypes.map((nt) => {
                    return {text: nt.title, value: nt.title}
                  })}
                  name='type'
                  label="Typ"
                />
            }
          </Grid>
          <Grid container item justifyContent='stretch'>
            <TextInputMuiFormik
              name='shortDescription'
              multiline
              rows={3}
              label="Kurzbeschreibung"
              disabled={readonly}
            />
          </Grid>
          {
            !readonly &&
              <Grid item container justifyContent='flex-end'>
                  <SubmitButtonMuiFormik text="Speichern"/>
              </Grid>
          }
        </Grid>
      </Form>
    </Formik>
  );
})