import { useState } from "react";

import { Typography, Stack, FormControl, InputLabel, Button, MenuItem, Select, Box } from "@mui/material";

import { LearningGoal } from "../../../../app/models/learningGoal";
import { useStore } from "../../../../app/stores/store";
import TextEditor from "../../../../app/common/form/TextEditor";


interface Params {
    learningGoals: LearningGoal[]
}
export default function ChooseLearningGoalModal({ learningGoals }: Params) {

    const { modalStore, nodeStore } = useStore()
    const { addLearningStatement } = nodeStore;

    const [learningGoalId, setLearningGoalId] = useState(learningGoals[0].id)

    function handleSave(text: string) {
        addLearningStatement(learningGoalId, text)
        modalStore.closeModal()
    }

    function getHeader(id: string) {
        const level = learningGoals.find(lg => lg.id === id)?.competenceLevel
        return `Aussage zu ${level?.number} - ${level?.title}`
    }

    function handleNextStep() {
        if (!learningGoalId) return

        modalStore.closeModal()
        modalStore.openModal(<Box sx={{ height: '670px' }}><TextEditor header={getHeader(learningGoalId)} incContent={""} onSave={handleSave} /></Box>, 'small')
    }

    return (
        <>
            <Typography mb={'12px'} variant="h5" component="div">
                Lernziel wählen:
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Lernziel</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={learningGoalId}
                    label="Lernziel"
                    onChange={(e) => { setLearningGoalId(e.target.value) }}
                >
                    {learningGoals.map(lg => <MenuItem value={lg.id} key={`li_${lg.id}`}>{`${lg.competenceLevel.number} - ${lg.competenceLevel.title}`}</MenuItem>)}
                </Select>
            </FormControl>
            <Stack mt={2} direction='row' justifyContent={'flex-end'} spacing={2}>
                <Button onClick={modalStore.closeModal} size="small">Abbrechen</Button>
                <Button variant='outlined' onClick={handleNextStep} size="small">Weiter</Button>
            </Stack>
        </>
    );
}