import {useState, useRef, useCallback, useEffect} from 'react';
import {observer} from 'mobx-react-lite';
import {unstable_usePrompt} from 'react-router-dom';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  XYPosition,
  EdgeTypes,
  Edge,
  Node as rfNode,
  Background,
  OnSelectionChangeParams,
  Node,
  NodeDragHandler,
} from 'reactflow';
import {toast} from 'react-toastify';
import {v4 as uuid} from 'uuid';

import {Box, CircularProgress, Divider, Stack} from '@mui/material';

import {useStore} from '../../app/stores/store';

import {EdgeData} from '../../app/models/edgeData';

import BottomBar from './BottomBar';
import ButtonEdge from './ButtonEdge';
import AddNodeModal from './AddNodeModal';
import ChooseEdgeTypeModal from './ChooseEdgeTypeModal';

import 'reactflow/dist/style.css';
import './staticReactFlowEditor.css';
import {NodeEdgeInfo} from '../../app/models/nodeEdgeInfo';

export default observer(function StaticReactFlowEditor(): any {
  const {modalStore, graphStore, nodeStore, boardStore} = useStore();
  const {
    loadGraphData,
    getNodesForStaticReactFlow,
    getEdgesForStaticReactFlow,
    getNodeStyle,
    saveGraphDataFromStaticReactFlow,
    loading,
    edgeIdsToBeDeleted,
    resetEdgeIdsToBeDeleted,
    addEdgeIdToBeDeleted
  } = graphStore;
  const {selectedBoardId, selectedBoard, loadBoard} = boardStore;

  const reactFlowWrapper: any = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(getNodesForStaticReactFlow);
  const [edges, setEdges, onEdgesChange] = useEdgesState(getEdgesForStaticReactFlow);
  const [reactFlowInstance, setReactFlowInstance]: any = useState(null);

  const [displayedBoardId, setDisplayedBoardId] = useState('');

  const reactFlowEdgeTypes = {
    buttonedge: ButtonEdge.bind(null, !selectedBoard?.readonly),
  };
  
  useEffect(() => {
    if (selectedBoardId && !loading) {
      if (selectedBoardId !== displayedBoardId) {
        loadBoard(selectedBoardId)
        loadGraphData(selectedBoardId)
        setDisplayedBoardId(selectedBoardId)
      }
      setNodes(getNodesForStaticReactFlow)
      setEdges(getEdgesForStaticReactFlow)
    }
    return (
      () => {
        setNodes([])
        setEdges([])
        resetEdgeIdsToBeDeleted()
        window.localStorage.setItem('isDirty', 'false')
      }
    )
  }, [loading, loadGraphData, resetEdgeIdsToBeDeleted, setNodes, setEdges, displayedBoardId, getEdgesForStaticReactFlow, getNodesForStaticReactFlow, selectedBoardId]);

  // remind user to save
  useEffect(() => {
    var timeout: NodeJS.Timeout | null = null
    if (window.localStorage.getItem('isDirty') === 'true') {
      timeout = setTimeout(() => {
        if (window.localStorage.getItem('isDirty') === 'true') {
          toast.info('Sie haben bereits einige Zeit lang nicht gespeichert.')
        }
      }, 900000);
    }
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [window.localStorage.getItem('isDirty')]);

  //fired when tab or browser is closed
  window.addEventListener("beforeunload", (ev) => {
    if (window.localStorage.getItem('isDirty') === 'true') {
      ev.preventDefault();
      return ev.returnValue = 'Änderungen verwerfen?';
    }
  });

  //fired when user navigates elsewhere
  unstable_usePrompt({when: window.localStorage.getItem('isDirty') === 'true', message: 'Änderungen verwerfen?'});//TODO: react router yet did not bring back a usePrompt entirely, over the time of programming check if this changes, see: https://github.com/remix-run/react-router/issues/8139 

  const onDropNewNode = (event: any) => {
    event.preventDefault();

    const type = event.dataTransfer.getData('application/reactflow');

    const isNotValid = (typeof type === 'undefined' || !type);
    if (isNotValid) return

    const position = getNewNodePosition(event)

    modalStore.openModal(<AddNodeModal addNode={handleAddBoardNode} position={position}
                                       graphNodes={reactFlowInstance.getNodes()}/>, 'large')
  };

  const getNewNodePosition = (event: any) => {
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

    const position: XYPosition = reactFlowInstance?.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    return position
  };

  const handleAddBoardNode = async (nodeId: string | null, title: string, shortDescr: string, nodeType: string, position: XYPosition, edges: NodeEdgeInfo[]) => {

    if (!title || title.length === 0) return
    if (!nodeType) return

    if (!nodeId) nodeId = await createNewNode(title, shortDescr, nodeType)

    if (nodeId) showBoardNode(nodeId, title, shortDescr, nodeType, position, edges)
  }

  const createNewNode = async (title: string, shortDescr: string, nodeType: string): Promise<string | null> => {

    const newNode = nodeStore.initiateNewNode()
    const nodeId = newNode.id

    newNode.title = title
    newNode.shortDescription = shortDescr
    newNode.type = nodeType

    try {
      await nodeStore.createNode(newNode)
      return nodeId
    } catch (error) {
      console.error(error)
    }
    return null
  }

  const showBoardNode = (nodeId: string, title: string, shortDescr: string, nodeType: string, position: XYPosition, edges: NodeEdgeInfo[]) => {

    const newRfNode: rfNode = {
      id: nodeId,
      position,
      data: {label: title, description: shortDescr, type: nodeType, edges: edges},
      style: getNodeStyle(nodeType)
    };

    setNodes((nds) => {
      nds.forEach(n => n.selected = false)
      newRfNode.selected = true
      return nds.concat(newRfNode)
    });

    showBoardNodesEdges(nodeId)

    window.localStorage.setItem('isDirty', 'true')

    modalStore.closeModal()
  }

  const showBoardNodesEdges = async (nodeId: string) => {
    var boardNodeEdges: EdgeData[] = []

    const allEdges = await nodeStore.getNodesEdges(nodeId)
    if (!allEdges) return

    const boardNodeIds = reactFlowInstance.getNodes().map((n: any) => n.id)
    //filter the edges where source- and target node are on the board
    boardNodeEdges = allEdges.filter(e => boardNodeIds.includes(e.sourceNodeId) && boardNodeIds.includes(e.targetNodeId))

    boardNodeEdges.forEach(e => {
      setEdges((eds) => addEdge({
        id: e.id,
        source: e.sourceNodeId,
        target: e.targetNodeId,
        label: e.type,
        type: "buttonedge"
      }, eds))
    })
  }

  const handleUpdateEdge = useCallback((oldEdge: Edge, newType: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    const newEdge = {...oldEdge, label: newType}
    setEdges((eds) => eds.filter((e) => e.id !== oldEdge.id))
    setEdges((eds) => addEdge(newEdge, eds))
    modalStore.closeModal()
    window.localStorage.setItem('isDirty', 'true')
  }, [modalStore, setEdges]);

  const onEdgeClick = useCallback((event: any, edge: Edge) => {
    const didClickEdgeButton = (typeof event.target.className === 'string' && event.target.className.includes('button'))
    const didDoubleClick = (event.detail === 2)

    if (!selectedBoard?.readonly && didClickEdgeButton && didDoubleClick) {
      modalStore.openModal(<ChooseEdgeTypeModal edge={edge} updateEdge={handleUpdateEdge}/>)
    }
  }, [handleUpdateEdge, modalStore])

  const onConnect = useCallback((params: any) => {
    setEdges((eds) => addEdge({...params, label: "?", id: uuid(), type: 'buttonedge'}, eds))
    window.localStorage.setItem('isDirty', 'true')
  }, [setEdges]);

  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const handleSaveGraph = async () => {
    const myNodes = reactFlowInstance.getNodes()
    const myEdges = reactFlowInstance.getEdges()

    await saveGraphDataFromStaticReactFlow(myNodes, myEdges, edgeIdsToBeDeleted, selectedBoardId)
      .then(resetEdgeIdsToBeDeleted)
      .then(() => window.localStorage.setItem('isDirty', 'false'))
      .then(() => toast.success('Gespeichert'))
  }

  const onEdgesDelete = useCallback(((edges: Edge[]) => {
    if (localStorage.getItem('didSelectOnlyOneEdge') === 'true') {//makes sure that edges can only be deleted if they were selected individually, no deletion shall take place as a sideproduct of reactflowNode deletion, also see onSelectionChange
      if (!edgeIdsToBeDeleted.includes(edges[0].id)) {
        addEdgeIdToBeDeleted(edges[0].id)
      }
    }
    window.localStorage.setItem('isDirty', 'true')
  }), [addEdgeIdToBeDeleted, edgeIdsToBeDeleted])

  const onNodesDelete = useCallback(((_: Node[]) => {
    window.localStorage.setItem('isDirty', 'true')
  }), [])

  const onSelectionChange = ({nodes, edges}: OnSelectionChangeParams) => {
    //deal with selection of node to display it on details section
    if (nodes.length === 1) {
      const nodeData = graphStore.transformFromReactFlowDataToGraphData(nodes, []).nodes[0]
      graphStore.setSelectedNode(nodeData)
    } else graphStore.setSelectedNode(null)

    //deal with edge selection to decide if it would be deleted or hidden
    if (edges.length === 1 && nodes.length === 0) localStorage.setItem('didSelectOnlyOneEdge', 'true')
    else localStorage.setItem('didSelectOnlyOneEdge', 'false')
  };

  const onNodeDrag: NodeDragHandler = useCallback(((_, __) => {
    window.localStorage.setItem('isDirty', 'true')
  }), [])

  return (
    <>
      {
        loading ? (
          <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 800}}>
            <CircularProgress/>
          </Box>
        ) : (
          <div className="dndflow">
            <ReactFlowProvider>
              <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                <ReactFlow
                  snapToGrid
                  nodes={nodes.map(value => ({
                    ...value,
                    deletable: !selectedBoard?.readonly,
                  }))}
                  edges={edges.map(value => ({
                    ...value,
                    deletable: !selectedBoard?.readonly,
                    focusable: !selectedBoard?.readonly,
                    ...(
                      selectedBoard?.readonly ?
                        {
                          selected: false,
                        } :
                        {}
                    )
                  }))}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onNodeDrag={onNodeDrag}
                  onConnect={onConnect}
                  onInit={setReactFlowInstance}
                  onDrop={onDropNewNode}
                  onDragOver={onDragOver}
                  fitView
                  edgeTypes={reactFlowEdgeTypes as EdgeTypes}
                  onEdgeClick={onEdgeClick}
                  onEdgesDelete={onEdgesDelete}
                  onNodesDelete={onNodesDelete}
                  onSelectionChange={onSelectionChange}
                  deleteKeyCode={['Backspace', 'Delete']}

                  // readonly specific configuration
                  nodesDraggable={!selectedBoard?.readonly}
                  nodesConnectable={!selectedBoard?.readonly}
                >
                  <Controls
                    showInteractive={!selectedBoard?.readonly}
                  />
                  <Background/>
                </ReactFlow>
              </div>
              <Divider sx={{mb: 2}}></Divider>
              {
                selectedBoard &&
                  <BottomBar
                      saveGraph={handleSaveGraph}
                      board={selectedBoard}
                  />
              }
            </ReactFlowProvider>
          </div>
        )}
    </>
  );
});