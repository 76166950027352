import {useStore} from "../../../app/stores/store";
import {observer} from "mobx-react-lite";

import {toast} from "react-toastify";
import * as Yup from 'yup';

import {Board} from "../../../app/models/board";
import {Grid} from "@mui/material";
import {Form, Formik} from "formik";

import TextInputMuiFormik from "../../../app/common/form/muiFormikComponents/TextInputMuiFormik";
import SubmitButtonMuiFormik from "../../../app/common/form/muiFormikComponents/SubmitButtonMuiFormik";

interface Params {
    isNew: boolean,
    setIsNew: React.Dispatch<React.SetStateAction<boolean>>,

    getHeader(title: string): string,

    setHeader: React.Dispatch<React.SetStateAction<string>>
}

export default observer(function BoardDetailsForm({isNew, setIsNew, getHeader, setHeader}: Params) {
    const {boardStore} = useStore()
    const {selectedBoard, create, update, initiateNewBoard} = boardStore

    async function handleFormSubmit(board: Board, {resetForm}: any) {
        try {
            if (isNew) {
                await create(board)
                setIsNew(false)
            } else {
                await update(board)
            }
            resetForm({values: board, dirty: false})
            setHeader(getHeader(board.title))
            toast.success('Gespeichert')
        } catch (error) {
            console.error(error)
            toast.error('Etwas lief schief: ' + error)
        }
    }

    const validationSchema = Yup.object({
        title: Yup.string().required('Bitte einen Titel vergeben'),
    })

    return (
        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={selectedBoard ? selectedBoard : initiateNewBoard()}
            onSubmit={handleFormSubmit}>
            <Form>
                <Grid container spacing={2}>
                    <Grid container item justifyContent='stretch'>
                        <TextInputMuiFormik name='title' label="Titel" disabled={selectedBoard?.readonly}/>
                    </Grid>
                    <Grid container item justifyContent='stretch'>
                        <TextInputMuiFormik name='description' multiline rows={4} label="Beschreibung"
                                            disabled={selectedBoard?.readonly}/>
                    </Grid>
                    {
                        !selectedBoard?.readonly &&
                        <Grid item container justifyContent='flex-end'>
                            <SubmitButtonMuiFormik text="Speichern"/>
                        </Grid>
                    }
                </Grid>
            </Form>
        </Formik>
    );
})