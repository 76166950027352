import { Box, Divider, Grid, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

import { useStore } from "../../app/stores/store";
import NavigationButton from "../../app/common/NavigationButton";
import { observer } from "mobx-react-lite";

export default observer(function NodeDetails() {

    const { graphStore } = useStore()
    const { selectedNode } = graphStore

    return (
        <Box height={700} sx={{ display: 'flex' }}>
            <Stack spacing={2} >
                <Typography variant="h5">Details</Typography>
                <Divider></Divider>
                {selectedNode && selectedNode.nodeId && selectedNode.nodeId.length > 0 ? (
                    <>
                        <Typography variant="h4">{selectedNode.title.length < 50 ? selectedNode.title : selectedNode.title.substring(0, 45) + '...'}</Typography>
                        <Typography>{selectedNode.description.length < 220 ? selectedNode.description : selectedNode.description.substring(0, 210) + '...'} </Typography>
                        <NavigationButton text='Zur Kompetenzbasis' route={`/nodes/${selectedNode.nodeId}`} />
                        <List sx={{ overflow: 'auto' }} >
                            {selectedNode.edges && selectedNode.edges.map(e => {
                                return <ListItem key={'li_' + e.edgeId} divider>
                                    <Grid container justifyContent='space-between' >
                                        <Grid item container direction='column'>
                                            <ListItemIcon sx={getStyle(e.direction)} >
                                                {e.direction === 'to' ? <ArrowForward /> : <ArrowBack />}
                                            </ListItemIcon>
                                            <ListItemText secondary={e.edgeType} />
                                        </Grid>
                                        <Grid item>
                                            <ListItemText primary={e.nodeTitle} secondary={e.nodeType} />
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            })}
                        </List>
                    </>
                ) : <Typography variant="h5" color='grey'>Keine Kompetenzbasis gewählt</Typography>
                }
            </Stack >
        </Box >
    );
})


function getStyle(direction: string) {

    var style = { color: '#bdbdbd' }

    if (direction === 'to') style.color = '#757575'

    return style
}