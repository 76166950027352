import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Box, Button, CircularProgress, Grid, IconButton, InputBase, Typography} from "@mui/material";
import {useStore} from "../../app/stores/store";
import BoardCard from "./BoardCard";
import {observer} from "mobx-react-lite";
import {Add, Search} from "@mui/icons-material";
import BoardDetailsForm from "./details/BoardDetailsForm";
import SortMenu from "../../app/common/SortMenu";
import {useDebounce} from "../../app/common/UtilHooks";

type SortType = "none" | "title" | "last_modified";

const SortTypes: Array<{
  type: SortType,
  title: string,
}> = [
  {
    type: "none",
    title: "None",
  },
  {
    type: "title",
    title: "Titel",
  },
  {
    type: "last_modified",
    title: "Last Modified Date",
  },
]

export default observer(function BoardScreen() {

  const {boardStore, modalStore} = useStore();
  const {boards, templateBoards, loadBoards, loadTemplateBoards, setSelectedBoard, loading} = boardStore;

  const [sortType, setSortType] = useState<SortType>("none");

  const [searchString, setSearchString] = useState('')
  const debouncedSearchString = useDebounce(searchString.trim(), 500).toUpperCase().trim()

  useEffect(() => {
    loadBoards()
    loadTemplateBoards()
  }, [loadBoards])

  const allBoards = useMemo(
    () => {
      let result = boards?.concat(templateBoards || []) ?? [];

      if (debouncedSearchString.length > 0) {
        result = result.filter(board => board.title.toUpperCase().indexOf(debouncedSearchString) > -1)
      }
      
      switch (sortType) {
        case "none":
          break;
        case "title":
          result = result.sort((a, b) => a.title.localeCompare(b.title))
          break;
        case "last_modified":
          result = result.sort((a, b) => new Date(b.modifiedOn).getTime() - new Date(a.modifiedOn).getTime())
          break;
      }

      return result;
    },
    [boards, templateBoards, sortType, debouncedSearchString]
  );

  return (
    <div>
      <Box sx={{overflowY: 'auto'}} height={'100%'} paddingX={10}>
        <Grid container alignContent='flex-start'>
          <Grid item xs={12} paddingY={4} paddingX={2}>
            <Typography variant="h4">Boards</Typography>
          </Grid>

          <Grid container item xs={12} paddingY={2} paddingX={2}>
            <Grid item xs>
              <Typography variant="h6">Insgesamt {allBoards.length} Boards</Typography>
            </Grid>

            <Grid container item spacing={1} xs justifyContent="end">
              <Grid item>
                <Box
                  sx={{
                    background: "rgba(213,169,238,0.6)",
                    borderRadius: "6px",
                  }}
                  paddingLeft={1}
                  paddingRight={2}
                >
                  <IconButton disableRipple>
                    <Search/>
                  </IconButton>

                  <InputBase
                    placeholder="Suche Board"
                    value={searchString}
                    onChange={e => setSearchString(e.target.value)}
                  />
                </Box>
              </Grid>

              <Grid item>
                <SortMenu sortType={sortType} setSortType={setSortType} choices={SortTypes}/>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Add/>}
                  onClick={() => modalStore.openModal(<CreateNewBoardModal/>, "small")}
                >
                  Board hinzufügen
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container paddingY={2} spacing={2}>
            {
              loading ?
                (
                  <Grid item display="flex" justifyContent="center" xs={12}>
                    <CircularProgress/>
                  </Grid>
                )
                :
                (
                  <>
                    {
                      allBoards.map((board) => (
                        <Grid item key={`gridItem_${board.id}`}
                              height="200px"
                              xs={12} md={6} lg={4} xl={3}
                        >
                          <BoardCard board={board} setSelectedBoard={setSelectedBoard}/>
                        </Grid>
                      ))
                    }
                  </>
                )
            }
          </Grid>

        </Grid>
      </Box>
    </div>
  );
});

function CreateNewBoardModal(props: {}) {
  const {boardStore, modalStore} = useStore()
  const {clearSelectedBoard} = boardStore;

  const [isNew, setIsNew] = useState(true)

  const hook_setIsNew = useCallback((value: boolean) => {
    if (!value) {
      boardStore.loadBoards().finally(() => {
        modalStore.closeModal();
      });
    }

    setIsNew(value)
  }, [setIsNew]) as React.Dispatch<React.SetStateAction<boolean>>;

  useEffect(() => {
    clearSelectedBoard()
  }, []);

  return (
    <Grid container spacing={2} padding={4}>
      <Grid container item xs={12} alignItems={'center'}>
        <Grid item xs={10}>
          <Typography variant="h4">Create new board</Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Typography variant="h5" color={'primary.light'}>Board</Typography>
        </Grid>
        <Grid item xs={12}>
          <BoardDetailsForm isNew={isNew} setIsNew={hook_setIsNew} getHeader={() => ""} setHeader={() => {
          }}/>
        </Grid>
      </Grid>
    </Grid>
  )
}