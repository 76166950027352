import * as React from 'react';
import {useState} from 'react';
import {CSSObject, styled, Theme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  Close,
  FlipToFront,
  FormatListBulleted,
  GridView,
  NavigateBefore,
  NavigateNext,
  QuestionMark,
  Settings,
  SupervisorAccount
} from '@mui/icons-material';
import {observer} from 'mobx-react-lite';
import {useStore} from '../stores/store';
import {useNavigate} from 'react-router-dom';
import {router} from '../router/Routes';
import {Avatar, Menu} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {Button} from "semantic-ui-react";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
  ({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const OpenedBoardsListView = observer(() => {
  const {boardStore} = useStore();

  return (
    <>
      <Box sx={{
        padding: '5px 20px'
      }}>
        <Typography variant='h6'>
          {
            boardStore.openedBoards.length == 0
              ? 'Keine geöffneten Boards'
              : 'Geöffneten Boards'
          }
        </Typography>
      </Box>

      <List sx={{
        maxHeight: '50%',
        overflowY: 'autohide'
      }}>
        {
          boardStore.openedBoards.map(board => (
            <ListItem>
              <ListItemButton
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
                onClick={() => router.navigate(`/graphEditor/${board.id}`)}
              >
                {board.title}
              </ListItemButton>

              <Box
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() => boardStore.remOpenedBoard(board.id)}
                width={32}
                justifyContent='center'
                display='flex'
                padding='5px'
              >
                <Close/>
              </Box>
            </ListItem>
          ))
        }
      </List>
    </>
  )
})

export default observer(function NavBarMui() {
  const navigate = useNavigate()
  const {accountStore} = useStore();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const {accountStore: {user, logout, isAdmin}, modalStore} = useStore();

  const [open, setOpen] = useState(true);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleJumpToProfile = () => {
    handleClose()
    navigate(`users/${user?.id}`)
  }

  const handleLogout = () => {
    handleClose()
    logout()
  }

  const admin = isAdmin();

  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline/>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingX: '10px'
          }}
        >
          {
            open &&
              <Typography variant='h6'>
                  CoKoMo
              </Typography>
          }

          <Button
            size='tiny'
            primary
            onClick={() => setOpen(!open)}
            icon={open ? <NavigateBefore/> : <NavigateNext/>}
          />
        </DrawerHeader>
        <Divider/>
        <List>
          <NavItem title='Boards' route='/boards' icon={<GridView/>} open={open}></NavItem>
          <NavItem title='Kompetenzpunkte' route='/nodes' icon={<FormatListBulleted/>} open={open}></NavItem>
          <Divider/>
        </List>

        {
          open &&
            <OpenedBoardsListView/>
        }

        <div style={{display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', height: "100%"}}>
          <List>
            {admin && <Divider/>}
            {admin && <NavItem title='Admin' route='/administration' icon={<SupervisorAccount/>} open={open}></NavItem>}
            {admin && <NavItem title='Vorlage' route='/draftOrgaSync' icon={<FlipToFront/>} open={open}></NavItem>}

            <div
              onClick={() => window.open('https://cokomo-it.de/docs/', '_blank')}
            >
              <NavItem
                title='Hilfe'
                route=''
                icon={<QuestionMark/>}
                open={open}/>
            </div>
          </List>

          <Divider/>

          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            sx={{
              padding: '12px',
            }}
          >
            <Box
              display='flex'
              alignItems='center'
            >
              <Avatar
                src={accountStore.user?.image}
                sx={{
                  width: '32px',
                  height: '32px'
                }}
              />

              {
                open &&
                  <>
                      <Box sx={{width: '10px'}}/>

                      <Typography>
                        {accountStore.user?.displayName ?? accountStore.user?.username}
                      </Typography>
                  </>
              }

            </Box>

            {
              open &&
                <div onClick={handleMenu}>
                    <Settings/>
                </div>
            }

            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleJumpToProfile}>Profil</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </Box>

        </div>
      </Drawer>
    </Box>
  )
})

interface NavParams {
  title: string,
  route: string,
  icon: any,
  open: boolean
}

function NavItem({title, route, icon, open}: NavParams) {

  const {modalStore} = useStore()

  return (<ListItem key={`li_${title}`} disablePadding sx={{display: 'block'}}>
    <ListItemButton
      disabled={modalStore.modal.open}
      onClick={() => router.navigate(route)}
      sx={{
        minHeight: 48,
        justifyContent: open ? 'initial' : 'center',
        px: 2.5,
        color: {}
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: open ? 3 : 'auto',
          justifyContent: 'center',
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText disableTypography primary={<Typography sx={{color: 'text.secondary'}}>{title}</Typography>}
                    sx={{opacity: open ? 1 : 0}}/>
    </ListItemButton>
  </ListItem>);
}