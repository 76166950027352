import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { Board } from "../models/board";
import { GraphData } from "../models/graphData";
import { NodeData } from "../models/nodeData";
import { PaginatedResult } from "../models/pagination";
import {
  User,
  UserDto,
  UserFormValues,
  UserFormValuesDto,
} from "../models/user";
import { router } from "../router/Routes";
import { store } from "../stores/store";
import { EdgeData } from "../models/edgeData";
import { NodeType } from "../models/nodeType";
import { EdgeType } from "../models/edgeType";
import { LearningGoalStatement } from "../models/LearningGoalStatement";
import { Organization, OrganizationFormValues } from "../models/organization";
import { BaseAgent } from "./common";

const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

axios.interceptors.request.use((config) => {
  const token = store.commonStore.token;
  if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    if (process.env.NODE_ENV === "development") await sleep(1000);
    const pagination = response.headers["pagination"];
    if (pagination) {
      response.data = new PaginatedResult(
        response.data,
        JSON.parse(pagination)
      );
      return response as AxiosResponse<PaginatedResult<any>>;
    }
    return response;
  },
  (error: AxiosError) => {
    const { data, status, config } = error.response as AxiosResponse;
    switch (status) {
      case 400:
        if (config.method === "get" && data.errors.hasOwnProperty("id")) {
          router.navigate("/not-found");
        }
        if (data.errors) {
          const modalStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modalStateErrors.push(data.errors[key]);
            }
          }
          throw modalStateErrors.flat();
        } else {
          toast.error(data);
        }
        break;
      case 401:
        toast.error("unauthorised");
        break;
      case 403:
        toast.error("forbidden");
        break;
      case 404:
        router.navigate("/not-found");
        break;
      case 500:
        store.commonStore.setServerError(data);
        router.navigate("/server-error");
        break;
    }
    return Promise.reject(error);
  }
);

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: any, config?: AxiosRequestConfig) =>
    axios.post<T>(url, body, config).then(responseBody),
  put: <T>(url: string, body: any, config?: AxiosRequestConfig) =>
    axios.put<T>(url, body, config).then(responseBody),
  del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Account = {
  current: () => requests.get<User>("account"),
  login: (user: UserFormValues) => requests.post<User>("/account/login", user),
  googleOAuth: (token: string) =>
    requests.post<User>("/account/googleoauth", null, {
      headers: { OAuthToken: token },
    }),
  meinBildungsraumOAuth: (code: string) =>
    requests.post<User>("/account/meinbildungsraumoauth", null, {
      headers: { OAuthCode: code },
    }),
  register: (user: UserFormValues) =>
    requests.post<User>("/account/register", user),
  acceptTerms: () => requests.put<void>("/account/acceptTerms", null),
};

class OrganizationAgent extends BaseAgent<
  Organization,
  OrganizationFormValues
> {
  constructor() {
    super("/organizations");
  }

  async listUsers(orgId: string) {
    return requests.get<UserDto[]>(`${this.baseUrl}/${orgId}/users`);
  }

  async updateFinalDraftOrganization() {
    return requests.put<Boolean>(
      `${this.baseUrl}/updateFinalDraftOrganization`,
      {}
    );
  }
}

class UserManagerAgent extends BaseAgent<UserDto, UserFormValuesDto> {
  constructor() {
    super("/users");
  }

  async setPassword(id: string, password: string) {
    return requests.put<Boolean>(`${this.baseUrl}/${id}/setPassword`, {
      password,
    });
  }
}

const Users = {
  listPaged: (params: URLSearchParams) => {
    return axios
      .get<PaginatedResult<User[]>>("/users", { params })
      .then(responseBody);
  },
  get: (id: string) => requests.get<User>(`/users/${id}`),
};

const Graphs = {
  getBoardsStaticGraph: (boardId: string) =>
    requests.get<GraphData>(`/graphs/boardgraph/${boardId}`),
  saveBoardsGraph: (
    boardId: string,
    graphData: GraphData,
    deletedEdgeIds: string[]
  ) => {
    return requests.post<void>(`/graphs/boardgraph/${boardId}`, {
      graph: graphData,
      deletedEdgeIds: deletedEdgeIds,
    });
  },
  listEdgeTypes: () => requests.get<EdgeType[]>("/edgetypes"),
};

const Nodes = {
  get: (id: string) => requests.get<NodeData>(`/nodes/${id}`),
  list: (searchString: string) =>
    requests.get<NodeData[]>(`/nodes?searchstring=${searchString}`),
  listTypes: () => requests.get<NodeType[]>("/nodetypes"),
  listCompetenceLevels: () => requests.get<any>("/competencelevels"),
  create: (node: NodeData) => requests.post<NodeData>("/nodes", node),
  delete: (id: string) => requests.del<void>(`/nodes/${id}`),
  updateBaseData: (node: NodeData) =>
    requests.put<void>(`/nodes/${node.id}`, node),
  updateRichDescription: (nodeId: string, description: string) => {
    requests.put<void>(`/nodes/${nodeId}/description`, {
      description: description,
    });
  },
  updateAltTerms: (nodeId: string, altTerms: string) => {
    requests.put<void>(`/nodes/${nodeId}/altTerms`, { altTerms: altTerms });
  },
  listEdges: (id: string) => requests.get<EdgeData[]>(`nodes/edges/${id}`),
};

const LearningGoalStatements = {
  create: (statement: LearningGoalStatement) =>
    requests.post<void>("/learninggoalstatements", statement),
  delete: (id: string) => requests.del<void>(`/learninggoalstatements/${id}`),
  update: (statement: LearningGoalStatement) =>
    requests.put<void>("/learninggoalstatements", statement),
};

const Boards = {
  get: (id: string) => requests.get<Board>(`/boards/${id}`),
  list: () => requests.get<[Board]>("/boards"),
  listTemplate: () => requests.get<[Board]>("/boards/template"),
  create: (board: Board) => requests.post<void>("/boards", board),
  update: (board: Board) => requests.put<void>(`/boards/${board.id}`, board),
  delete: (boardId: string) => requests.del<void>(`/boards/${boardId}`),
};

const agent = {
  Account,
  Graphs,
  Nodes,
  LearningGoalStatements,
  Boards,
  Users,
  Organizations: new OrganizationAgent(),
  UsersManager: new UserManagerAgent(),
};

export default agent;